import * as React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo";
import {PaperClipIcon} from '@heroicons/react/outline'

const Press = () => (
    <Layout>
        <Seo title="KPF.NRW Pressearchiv"/>
        <div className="bg-white py-16">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              KPF.NRW
            </span>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Pressearchiv
            </span>
                    </h1>
                </div>
            </div>
        </div>
        <StaticQuery
            query={graphql`
    query MyQuery {
  allDownloadsCsv {
    edges {
      node {
        title
        description
        date
        contact
        mail
        filename
        rights
      }
    }
  }
    }
  `}

            render={data => (
                <div className="space-y-6">
                    {data.allDownloadsCsv.edges.map(({node}, i) => (

                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" key={i}>{node.title}</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500" key={i}>{node.description}</p>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Kontakt</dt>
                                        <dd className="mt-1 text-sm text-gray-900" key={i}>{node.contact}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">eMail</dt>
                                        <dd className="mt-1 text-sm text-gray-900" key={i}>
                                            {node.mail}
                                        </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Datum</dt>
                                        <dd className="mt-1 text-sm text-gray-900" key={{i}}>{node.date}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">&nbsp;</dt>
                                        <dd className="mt-1 text-sm text-gray-900" key={{i}}>{node.rights}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <PaperClipIcon className="h-6 w-6 text-gray-500" aria-hidden="true"/>
                                        <span className="mt-1 text-sm text-gray-900 truncate"
                                              key={{i}}><a href={node.filename} download>{node.filename}</a></span>
                                    </div>
                                </dl>
                            </div>
                        </div>

                    ))}
                </div>
            )}
        />
    </Layout>
)


export default Press